// WRITE ARTICLE
export const giftSharedDatapoints = {
  order: 1,
  name: "Gift shared datapoints",
  subcategory: "Share datapoints",
  markdown: `# Gift shared datapoints

  In Tower Hunt, it {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}costs money{% /inlineRouterLink %} to access {% inlineRouterLink articledId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %}. Because of this, when you {% inlineRouterLink articleId="share-datapoints" %}share Safezone datapoints{% /inlineRouterLink %} you have the option to *gift* &mdash; meaning pay for &mdash; access to them on a recipient-by-recipient basis. Read on to learn how to do this and why it is useful.

  {% callout type="note" %}
  **Note:** By default, recipients pay for their own usage. You must opt in to gifting.
  {% /callout %}

  ## What to expect

  When you gift access to a Safezone datapoint, you are agreeing to pay for the recipient's usage of the datapoint for a certain period of time. Make sure you {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}understand the mechanics of pay-as-you-go pricing{% /inlineRouterLink %} when you decide how long to gift access for. The options are:

  - Recipient pays (default)
  - You pay for 1 week
  - You pay for 1 month
  - You pay for 6 months
  - You pay for 1 year
  - You always pay

  ## Set recipient gift duration

  {% tablessContainer marginStyles="mb-4" %}
  1. {% inlineRouterLink articleId="share-datapoints" %}Add Safezone datapoints and sharing recipients{% /inlineRouterLink %} to the {% inlineRouterLink articleId="key-actions-hub" %}Sharing Stage{% /inlineRouterLink %}.
  2. Click the {% inlineAppIcon iconName="dollar" /%}**gift options button** to the left of a recipient's name.
  3. Choose a gift option from the dropdown.
  4. Notice that the affected recipient's {% inlineAppIcon iconName="gift" /%}**gift options button** is now an orange present.
  5. Click **Share and pay**.
  ![Sharing gift menu GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  {% callout type="tip" %}
  **Tip:** Gifts are tied to sharing access. To terminate a gift, {% inlineRouterLink articleId="share-datapoints" %}remove the recipient's sharing access{% /inlineRouterLink %}.
  {% /callout %}

  ## Example use cases

  One of the benefits of {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} is the structure they provide, which makes them more actionable than other formats that you might share with market contacts. In light of this, there are a variety of ways you can employ gifting to add value in the market:

  - After meeting with a current/potential client, follow-up with a Tower Hunt gift that includes Safezone datapoints you discussed.
  - Gift a limited partner (LP) with open-ended access to Safezone datapoints related to a joint venture asset.
  - Gift a market acquiantance (ex. journalist, vendor) with short-term access to certain Safezone datapoints.
  - As part of a marketing process for a sale (or other capital markets transaction), gift prospects with short-term access to Safezone datapoints from the Offering Memorandum.

  In each case, the gifted datapoints immediately become part of the recipient's view of the market in Tower Hunt. When mixed with the recipient's existing public and Safezone datapoints, the gift can easily lead to new insights and opportunities that might be missed if your data only existed in a conversation, email, PDF, or spreadsheet.`,
};
